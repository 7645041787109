import * as React from "react"
import { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import ReactGA from "react-ga"

// Context
import ScrollSafeContext from "../context/scrollSafeContext"

// Styles
import "../styles/utilities/_post-helpers.scss"

// Componenets
import Image from "gatsby-image"
import SEO from "../components/seo"
import Modules from "../components/Modules"
import PostFooter from "../components/PostFooter"
import { Section } from "../components/StyledComponents/layoutComponentStyles"

// Animations
import PostHero from "../components/PostHero"
import { pageVars } from "../animations/pages/journalAnim"
import { motion } from "framer-motion"
import { galleryScrub } from "../animations/pages/homeAnim"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Get data from the server
export const query = graphql`
  query($slug: String) {
    sanityPosts(slug: { current: { eq: $slug } }) {
      author {
        name
      }
      mainImage {
        altText
        image {
          asset {
            fluid(maxWidth: 2500) {
              ...GatsbySanityImageFluid
            }
            extension
            url
          }
        }
      }
      publishedAt(formatString: "Do MMM YYYY")
      slug {
        current
      }
      timeToRead
      title
      moduleContent {
        ... on SanityFullscreenImage {
          _key
          _type
          image {
            altText
            image {
              asset {
                fluid(maxWidth: 2500) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityImageCarousel {
          _key
          _type
          images {
            altText
            image {
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityImageGrid {
          _key
          _type
          images {
            altText
            image {
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityInsetImage {
          _key
          _type
          image {
            altText
            image {
              asset {
                fluid(maxWidth: 2500) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityProjectBanner {
          _key
          _type
          _rawBannerContent
          bannerTitle
        }
        ... on SanityTestimonial {
          _key
          _type
          testimonialCompany
          testimonialName
          testimonialQuote
        }
        ... on SanityBlockText {
          _key
          _type
          _rawContent
        }
        ... on SanityVideo {
          _key
          _type
          asset {
            url
          }
        }
      }
      postCategories {
        title
      }
    }
    allSanityPosts(limit: 2, sort: { fields: order, order: ASC }, filter: { slug: { current: { ne: $slug } } }) {
      edges {
        node {
          author {
            name
          }
          mainImage {
            image {
              asset {
                fluid(maxWidth: 2500) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
            altText
          }
          timeToRead
          title
          slug {
            current
          }
          publishedAt(formatString: "DD MMM")
          excerpt
          postCategories {
            title
          }
        }
      }
    }
  }
`

/*









*/

const BlogPost = ({ data }: any) => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)

  // Effects
  useEffect(() => {
    if (scrollSafe) {
      galleryScrub(".c-post-hero-img__asset")

      const images: Array<Element> = Array.from(
        document.querySelectorAll(".project-img-full__asset, .project-img-contained__asset, .project-img-grid__asset")
      )

      images.forEach(img => galleryScrub(img))
    }
  }, [scrollSafe])

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 450)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  return (
    <>
      <SEO title={data.sanityPosts.title} />
      {scrollSafe && (
        <>
          <PostHero post={data.sanityPosts} />
          <motion.div initial='initial' animate='animate' exit='exit' variants={pageVars} className='c-post'>
            <Section className='c-post-hero__img'>
              <Image fluid={data.sanityPosts.mainImage.image.asset.fluid} alt='placeholder' className='c-post-hero-img__asset' />
            </Section>

            {data.sanityPosts.moduleContent.map((module: any, index: number) => (
              <Modules module={module} key={index} />
            ))}

            <PostFooter data={data} />
          </motion.div>
        </>
      )}
    </>
  )
}

export default BlogPost
