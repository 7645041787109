import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"

// Types
import { Variants } from "framer-motion"

// Utils
import { $speedP1 } from "../animUtils"

/*








*/

/**
 * @animation
 *
 * @description
 * @returns {gsap.core.Tween} Gsap tween
 */
export const postTitleAnim = (el: any): gsap.core.Tween =>
  gsap.from(el, {
    y: "200%",
    skewY: "10%",
    duration: 0.6,
    ease: "expo.out",
    stagger: 0.2,
    delay: 0.6,
  })

/**
 * @animation
 *
 * @description
 * @returns {gsap.core.Tween} Gsap tween
 */
export const createPostTitleAnim = (el: any): void => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "lines",
        linesClass: "u-overflow-reveal",
      })
      return postTitleAnim(splitInner.lines)
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "lines",
        linesClass: "u-overflow-reveal",
      })
      return postTitleAnim(splitInner.lines)
    }, 200)
  }
}

// Variants

export const pageVars: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: $speedP1, ease: "linear", delay: 1.2 },
  },
  exit: { opacity: 0, transition: { duration: $speedP1, ease: "linear" } },
}

export const heroVars: Variants = {
  initial: { opacity: 1 },
  animate: { opacity: 1, transition: { duration: 0.1, ease: "linear" } },
  exit: { opacity: 0, transition: { duration: $speedP1, ease: "linear" } },
}

export const detailsParent: Variants = {
  initial: {},
  animate: { transition: { delayChildren: 1.5, staggerChildren: 0.2 } },
  exit: {},
}

export const detailsChild: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: $speedP1, ease: "linear" },
  },
  exit: { opacity: 0, transition: { duration: $speedP1, ease: "linear" } },
}
