import * as React from "react"
import { useEffect, useRef } from "react"

// Styles
import "./_post-hero.scss"

// Components
import { Section, Container } from "../StyledComponents/layoutComponentStyles"

// Animations
import { motion } from "framer-motion"
import { detailsParent, detailsChild, createPostTitleAnim, heroVars } from "../../animations/pages/journalAnim"

interface PostHeroProps {
  post: any
}

/*









*/

const PostHero: React.FunctionComponent<PostHeroProps> = ({ post }) => {
  const titleRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    createPostTitleAnim(titleRef.current)
  }, [])

  return (
    <Section>
      <Container fourteenClm>
        <motion.div className='c-post-hero' initial='initial' animate='animate' exit='exit' variants={heroVars}>
          <motion.div initial='initial' animate='animate' exit='exit' variants={detailsParent} className='c-post-hero__details'>
            <motion.div variants={detailsChild} className='c-post__detail'>
              <p className='o-accent'>Written by:</p>
              <p>{post.author.name}</p>
            </motion.div>
            <motion.div variants={detailsChild} className='c-post__detail'>
              <p className='o-accent'>Published:</p>
              <p>{post.publishedAt}</p>
            </motion.div>
          </motion.div>
          <h2 ref={titleRef}>{post.title}</h2>
        </motion.div>
      </Container>
    </Section>
  )
}

export default PostHero
