import * as React from "react"
import { useContext } from "react"

// Styles
import "./_post-footer.scss"

// Components
import { Link } from "gatsby"
import Image from "gatsby-image"
import CursorContext from "../../context/cursorContext"
import { Container, PostFooterContainer, Section } from "../StyledComponents/layoutComponentStyles"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa"

// Animations

interface PostFooterProps {
  data: any
}

/*









*/

const PostFooter: React.FunctionComponent<PostFooterProps> = ({ data }) => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <Section>
      <Container fourteenClm>
        <PostFooterContainer>
          <div className='c-post-footer__details'>
            <div className='c-post__detail'>
              <p className='o-accent'>Written by:</p>
              <p>{data.sanityPosts.author.name}</p>
            </div>
            <div className='c-post__detail'>
              <p className='o-accent'>Published:</p>
              <p>{data.sanityPosts.publishedAt}</p>
            </div>
            <div className='c-post__detail'>
              <p className='o-accent'>Share:</p>
              {/* 
                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <FacebookShareButton
                url={document.baseURI}
                style={{ outline: "none" }}
                onMouseEnter={() => setCursorState?.("is-link")}
                onMouseLeave={() => setCursorState?.("")}
              >
                <FaFacebook className='c-social-share-icon' color='rgba(250, 250, 250, 1)' />
              </FacebookShareButton>
              {/* 
                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <TwitterShareButton
                url={document.baseURI}
                style={{ outline: "none" }}
                onMouseEnter={() => setCursorState?.("is-link")}
                onMouseLeave={() => setCursorState?.("")}
              >
                <FaTwitter className='c-social-share-icon padded' color='rgba(250, 250, 250, 1)' />
              </TwitterShareButton>
              {/* 
                      // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <LinkedinShareButton
                url={document.baseURI}
                style={{ outline: "none" }}
                onMouseEnter={() => setCursorState?.("is-link")}
                onMouseLeave={() => setCursorState?.("")}
              >
                <FaLinkedin className='c-social-share-icon' color='rgba(250, 250, 250, 1)' />
              </LinkedinShareButton>
            </div>
          </div>
          <h3>More like this</h3>
          <div className='o-journal-posts footer-suggestions'>
            {data.allSanityPosts.edges.map(({ node }: any) => (
              <div className='o-journal-post' key={node.slug.current}>
                <Link
                  to={`/${node.slug.current}/`}
                  onMouseEnter={() => setCursorState?.("is-post")}
                  onMouseLeave={() => setCursorState?.("")}
                >
                  <div className='c-post-img'>
                    <Image
                      fluid={node.mainImage.image.asset.fluid}
                      alt={node.title}
                      style={{
                        transition: "all 0.9s cubic-bezier(0.16, 1, 0.3, 1)",
                      }}
                      imgStyle={{
                        transition: "all 0.9s cubic-bezier(0.16, 1, 0.3, 1)",
                      }}
                    />
                  </div>
                </Link>
                <div className='c-post-details'>
                  <div className='c-post-details__banner'>
                    <Link
                      to={`/${node.slug.current}/`}
                      onMouseEnter={() => setCursorState?.("is-link")}
                      onMouseLeave={() => setCursorState?.("")}
                    >
                      <h6>{node.title}</h6>
                    </Link>
                    <p className='o-accent'>{node.publishedAt}</p>
                  </div>
                  <p className='post-excerpt'>{node.excerpt}</p>
                  <p>
                    {node.author.name} <span> in </span>
                    {node.postCategories.map((cat: any) => cat.title)}
                    <span>&nbsp;&nbsp; • &nbsp;&nbsp;</span>
                    {node.timeToRead} min read
                  </p>
                </div>
              </div>
            ))}
          </div>
        </PostFooterContainer>
      </Container>
    </Section>
  )
}

export default PostFooter
